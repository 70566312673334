import React, {Fragment} from 'react';
import {graphql} from 'gatsby';

import Meta from '@components/Meta';
import HeroSectionStandalone from '@components/Hero';
import CanonicalUrl from '@components/CanonicalUrl';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {
  LatestSection,
  SubNavSection,
  StyledSection,
  FullWidthImageSection,
  OneImageWithContentSection,
  ThreeImagesWithContentSection,
} from '@components/sections';
import TwoUOSParts from '@components/TwoUOSParts';
import {HeroSectionCallout} from '@components/SectionCallout';
import getDataFromBlockTypes from '@util/getDataFromBlockTypes';
import {ThemeProvider, styled, Colors} from '@styles';
import {detailPagesData} from '@components/Subnavigation/detailPagesData';

const LatestStyledSection = styled(LatestSection).withConfig({
  componentId: 'manifestoLatest'
})`
  background: ${Colors.TintedFogLight};
`;

const DarkContainer = styled(StyledSection).withConfig({
  componentId: 'missionDarkContainer'
})`
  background: ${Colors.Elm};
  color: ${Colors.Dandelion};
`;

export default function Manifesto({data}): JSX.Element {
  const {cms} = data;
  const {
    hero,
    body,
    searchDescription,
    seoTitle,
    canonicalUrl,
    parentPage,
  } = cms.page;
  
  const {header: heroHeader, image: heroImage} = getDataFromBlockTypes(hero);

  const {
    about,
    career,
    partner,
    commitment,
    discover,
    approach,
    goal,
    vision
  } = getDataFromBlockTypes(body);

  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider
        theme={{
          minPadding: '2em',
          bg: Colors.Transparent,
          fg: Colors.White,
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider theme={{maxWidth: '600px'}}>
            <HeroSectionStandalone heroSectionType={'short'} secondaryHero>
              <HeroSectionCallout heroProps={[heroHeader]} />
            </HeroSectionStandalone>
          </ThemeProvider>

          <SubNavSection
            filter={false}
            categories={detailPagesData}
            backLinkText={parentPage && parentPage.title}
            backLinkUrl={parentPage && parentPage.url}
          />

          {heroImage && heroImage.image && (
            <FullWidthImageSection
              style={{maxHeight: '650px'}}
              {...heroImage}
            />
          )}
          { about && (
            <ThreeImagesWithContentSection
              variant="three"
              contentLeft
              margin="9em 0 2em"
              {...about}
            />
          )}
          { vision && (
            <ThemeProvider theme={{fg: Colors.White, bg: Colors.Elm, headlineColor: Colors.White, stairHeadlineColor: Colors.Dandelion, ctaIconBg: Colors.Dandelion}}>
              <DarkContainer>
                <TwoUOSParts rows={2} {...vision} expandLabel='the list of vision items' />
              </DarkContainer>
            </ThemeProvider>
          )}

          { career && <ThreeImagesWithContentSection variant="four" {...career} /> }

          { partner && (
            <OneImageWithContentSection
              withBg
              contentLeft
              theme={{
                bg: Colors.Midnight,
                fg: Colors.White,
                ctaFg: Colors.White,
                ctaBg: Colors.Transparent,
                ctaHoverBg: Colors.White,
                ctaHoverFg: Colors.Blue,
              }}
              {...partner}
            />
          )}

          { commitment && (
            <ThreeImagesWithContentSection
              variant="four"
              margin="9em 0 4em"
              {...commitment}
            />
          )}
          { discover && (
            <ThemeProvider
              theme={{
                fg: Colors.Charcoal,
                bg: Colors.Transparent,
                ctaFg: Colors.Charcoal,
                ctaBg: Colors.Transparent,
                ctaHoverFg: Colors.White,
                ctaHoverBg: Colors.Blue,
                ctaBorder: Colors.Charcoal,
                ctaBorderHover: Colors.Blue,
              }}
            >
              <OneImageWithContentSection contentLeft {...discover} />
            </ThemeProvider>
          )}
          { approach && (
            <ThemeProvider
              theme={{
                bg: Colors.Charcoal,
                maxPadding: 'unset',
              }}
            >
              <StyledSection>
                <ThreeImagesWithContentSection
                  variant="two"
                  theme={{
                    fg: Colors.White,
                    bg: Colors.Charcoal,
                    ctaFg: Colors.White,
                    ctaBg: Colors.Transparent,
                    ctaHoverFg: Colors.Blue,
                    ctaHoverBg: Colors.White,
                    minPadding: 'unset',
                    maxPadding: '2em 0',
                  }}
                  {...approach}
                />
              </StyledSection>
            </ThemeProvider>
          )}

          { goal && (
            <ThreeImagesWithContentSection
              variant="three"
              margin="9em 0 6em"
              contentLeft
              {...goal}
            />
          )}

          <LatestStyledSection />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  fragment ManifestoFragment on CMS_ManifestoPage {
    seoTitle
    searchDescription
    canonicalUrl
    parentPage {
      title
      url
    }
    hero {
      __typename
      ...headerBlockFragment
      ...pagelinkBlockFragment
      ...heroCmsImageFragment
    }
    body {
      __typename
      ... on CMS_SectionBodyMediaGridBlock {
        __typename
        blockType
        headerbodyBlock {
          headline
          body
        }
        pagelinkBlock {
          ...pagelinkBlockFragment
        }
        filelinkBlock {
          ...fileBlockFragment
        }
        externallinkBlock {
          ...externallinkBlockFragment
        }
        mediagridBlock {
          imageBlocks {
            caption
            attribution
            image {
              ...cmsFluidImageFragment
            }
          }
          videoBlocks {
            video {
              url
            }
            youtube
            attribution
            caption
            image {
              ...cmsFluidImageFragment
            }
          }
        }
      }
      ... on CMS_IconCopyGridBlock {

        ...iconImageGridFragment
      }
      ... on CMS_SectionBodyVideoBlock {
        __typename
        blockType
        headerbodyBlock {
          headline
          body
        }
        pagelinkBlock {
          blockType
          title
          page {
            url
          }
        }
        videoBlock {
          video {
            title
            file
            url
          }
          youtube
          attribution
          caption
          image {
            ...cmsFluidImageFragment
          }
        }
      }
      ...sectionBodyImageFragment
    }
  }

  query Manifesto {
    cms {
      page(url: "/home/about/mission/") {
        ...ManifestoFragment
      }
    }
  }
`;
